import React  from 'react'
import { useTable, useSortBy } from 'react-table'
import BTable from 'react-bootstrap/Table';
import { FiArrowUpCircle, FiArrowDownCircle } from "react-icons/fi";
import { RiInboxUnarchiveLine } from "react-icons/ri"
import Button from 'react-bootstrap/Button'
import I18n from 'i18n-js';
 
export default function ArchiveTable(props) {
  const data = props.data
  const columns = props.columns

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
      { 
        columns,
        data
      },
      useSortBy
    )

  return (
    <BTable striped bordered hover size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className="align-baseline" {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')} &nbsp;
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <FiArrowDownCircle />
                      : <FiArrowUpCircle />
                      : ''
                  }
              </th>
            ))}
            <th>{I18n.t('activerecord.attributes.monitoring_request.restore')}</th>
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {
                      props.useRenderFunction
                      ? props.cellContent(cell, row)
                      : cell.render('Cell') 
                    }
                  </td>
                )
              })}
              <td className="cell-text-ctr">{row.original["request_status_id"] === 'discarded' ? <Button variant="link" size="lg" className="btn-unarchive" onClick={() => props.unarchive(row["original"]["id"])}><RiInboxUnarchiveLine /></Button> : ""}</td>
            </tr>
          )
        })}
      </tbody>
    </BTable>
  )
}