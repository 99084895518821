import React, { useEffect, useState } from 'react'
import { appConstants } from 'utils/constants.js'
import { Container, Row, Col, Button } from 'react-bootstrap'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'

export default function CollectionDetails(props) {
  const [collection, setCollection] = useState({})
  const [collectionResources, setCollectionResources] = useState([])
  const [show, setShow] = useState(props.show)

  const loadCollection = async() => {
    if (show) {
      const res = await axios.get(`${appConstants.COLLECTIONS_URL}${props.id}.json`)
      setCollection(res.data)
      setCollectionResources(res.data["resources"])
    }
  }

  useEffect(() => {
    loadCollection()
  }, [props.id, props.show])

  const openResource = (r) => {
    setShow(false);
    props.openResource(r);
  }

  const renderEditButton = (c) => {
    return c.can_edit ? (
      <Button variant="outline-primary" href={`${appConstants.COLLECTIONS_URL}${props.id}/edit`}>{I18n.t('navigation.edit')}</Button>
    ) : ""
  }

  const renderDeleteButton = (c) => {
    return c.can_delete ? (
      <Button variant="outline-danger" href={`${appConstants.COLLECTIONS_URL}${props.id}/delete`}>{I18n.t('navigation.delete')}</Button>
    ) : ""
  }

  return (
    <Container>
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.collection.description')}</dt>
        </Col>
        <Col md={9}>
          <dd>{collection.description}</dd>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.collection.resources')}</dt>
        </Col>
        <Col md={9}>
          <dd>{collectionResources.map((r) => <p key={r.id}><Button variant="link" onClick={()=>openResource(r, props.id)}>{r.name}</Button></p>)}</dd>
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          {renderEditButton(collection)} 
        </Col>
        <Col md={2}>
          {renderDeleteButton(collection)}
        </Col>
      </Row>
    </Container>
  )
}
