import React, { useEffect, useState }  from "react"
import axios from 'axios'
import Select from 'react-select'
import I18n from 'i18n-js/locales.js'

export default function SelectSingle(props) {
  const [options, setOptions] = useState([props.url])

  const loadOptions = async () => {
    const res = await axios.get(props.url)
    let values = res.data.map(i => ({value: i.id, label: i.name}))
    if (props.isFilter) {
      values.unshift({value: -1, label: I18n.t("selects.unassigned")})
    }
    setOptions(values)
  }

  useEffect(() => {
    loadOptions(props.url)
  }, [props.url])

  const getSelectValue = () => {
    if (props.isDependent) {
      return props.selected ? options.find(obj => obj.value === props.selected) : null
    } else {
      return options.find(obj => obj.value === props.selected)
    }
  }

  return (
    <Select 
      clearValue={true}
      isClearable={props.isClearable}
      onChange={props.onValueChange} 
      value={getSelectValue()}
      options={options}
      placeholder={props.placeholder}
      styles={{
        menu: provided => ({ ...provided, zIndex: 9999 })
      }} />
  )
}