import React, { useEffect, useState } from 'react'
import I18n from 'i18n-js/locales.js'
import ImportForm from '../forms/ImportForm'
import { appConstants } from 'utils/constants.js'
import { Container, Row, Col, Button, Tab, Tabs, Spinner } from 'react-bootstrap'
import axios from 'axios'
import ReactJson from 'react-json-view'
import { CSVLink } from 'react-csv'
import SortableTable from '../tables/SortableTable.js'
import { BiSync, BiCheckDouble } from 'react-icons/bi'

export default function FulcrumFormDetails(props) {
  const [fulcrumForm, setFulcrumForm] = useState({})
  const [webhook, setWebhook] = useState({})
  const [questions, setQuestions] = useState([])
  const [questionColumns, setQuestionColumns] = useState([])
  const [records, setRecords] = useState([])
  const [recordColumns, setRecordColumns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [syncRecordID, setSyncRecordID] = useState("")


  const loadForm = async() => {
    const res = await axios.get(`${appConstants.FULCRUM_FORMS_URL}/${props.fulcrumFormId}`)
    setFulcrumForm(res.data.form)
    setWebhook(res.data.webhook)
    setQuestions(res.data.questions)
    setQuestionColumns(res.data.question_columns)
    setRecords(res.data.records)
    setRecordColumns(res.data.record_columns)
    setIsLoading(false)
  }

  const updateForm = async() => {
    setIsLoading(true)
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const res = await axios.put(`${appConstants.FULCRUM_FORMS_URL}/${props.fulcrumFormId}`)
    setFulcrumForm(res.data.form)
    setWebhook(res.data.webhook)
    setQuestions(res.data.questions)
    setQuestionColumns(res.data.question_columns)
    setRecords(res.data.records)
    setRecordColumns(res.data.record_columns)
    setIsLoading(false)
    props.refreshData()
  }

  const syncRecord = async(recordID) => {
    setSyncRecordID(recordID)
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const res = await axios.put(`${appConstants.FULCRUM_FORMS_URL}/${props.fulcrumFormId}/sync_record/${recordID}`)
    setRecords(res.data.records)
    setRecordColumns(res.data.record_columns)
    setSyncRecordID("")
  }

  const renderDownloadLink = () => {
    return questions.length > 0 ? (
      <CSVLink data={questions}>{I18n.t("headers.fulcrum_form.download_questions")}</CSVLink>
    ) : ""
  }

  const externalRecordLink = (cell, row) => {
    let cellContent = cell.render('Cell') 
    if (cell["column"]["id"] === "record_id") {
      cellContent = <a href={appConstants.FULCRUM_URL + "records/" + row["original"]["record_id"]} target="_blank" rel="noopener noreferrer" key={row["original"]["record_id"]}>{row["original"]["record_id"]}</a>
    } else if (cell["column"]["id"] === "is_synchronized" && row["original"]["is_synchronized"] == "False" && syncRecordID != row["original"]["record_id"]) {
      cellContent = <Button  variant="outline-secondary" size="sm" onClick={() => syncRecord(row["original"]["record_id"])}><BiSync /></Button>
    } else if (cell["column"]["id"] === "is_synchronized" && row["original"]["is_synchronized"] == "True" && syncRecordID != row["original"]["record_id"]) {
      cellContent = <Button  variant="outline-success" size="sm" onClick={() => syncRecord(row["original"]["record_id"])}><BiCheckDouble /></Button>
    } else if (cell["column"]["id"] === "is_synchronized" && syncRecordID == row["original"]["record_id"]) {
      cellContent = <Button  variant="outline-secondary" size="sm" ><Spinner animation="grow" size="sm" /></Button>
    }

    return (
      cellContent 
    )
  }

  useEffect(() => {
    loadForm()
  }, [])

  const renderAll = () => {
    return isLoading ? (
      <Spinner animation="grow" />
    ) : <Container>
          <Row>
            <Col md={3}>
              <dt>{I18n.t('activerecord.attributes.fulcrum_form.name')}</dt>
            </Col>
            <Col md={9}>
              <dd>{fulcrumForm.name}</dd>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <dt>{I18n.t('activerecord.attributes.fulcrum_form.external_id')}</dt>
            </Col>
            <Col md={9}>
              <dd><a href={appConstants.FULCRUM_URL + "apps/" + fulcrumForm.external_id + "/edit"} target="_blank" rel="noopener noreferrer">{fulcrumForm.external_id}</a></dd>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button variant="outline-primary" style={{float: 'right'}} onClick={() => {updateForm()}}>{I18n.t("headers.fulcrum_form.update_remote")}</Button> 
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <br />
              <Tabs defaultActiveKey="records" id="listTabs">
                <Tab eventKey="uploaded_questions" title={I18n.t("headers.fulcrum_form.upload_questions")}>
                  <br />
                  {renderDownloadLink()} 
                  <br /><br />
                  <ImportForm 
                    userID={props.userID}
                    schema={props.schema}
                    guidance={"import.question_guidance"}
                    importUrl={`${appConstants.QUESTION_IMPORT_URL}/${props.fulcrumFormId}`}
                    authenticityToken={props.authenticityToken}
                    licenseKey={props.licenseKey}
                  />
                </Tab>
                <Tab eventKey="questions" title={I18n.t("headers.fulcrum_form.questions")}>
                  <SortableTable
                    data={questions}
                    columns={questionColumns}
                    useRenderFunction={false}
                  />  
                </Tab>
                <Tab eventKey="records" title={I18n.t("headers.fulcrum_form.records")}>
                  <SortableTable
                    data={records}
                    columns={recordColumns}
                    useRenderFunction={true}
                    cellContent={externalRecordLink}
                  />  
                </Tab>
                <Tab eventKey="webhook" title={I18n.t("headers.fulcrum_form.webhook")}>
                  <ReactJson src={webhook} collapsed={false} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
  }

  return (
    <>
      {renderAll()} 
    </>
  )
}
