import React   from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import styled from 'styled-components'

const StyledMapContainer = styled(MapContainer)`
  height: 700px;
  `;
const iraq = [33.2232, 43.6739]

export default function EventMap(props) {

  const markerContent = props.markerContent

  return (
    <StyledMapContainer center={iraq} zoom={6} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup showCoverageOnHover={false}>
        { props.data.map(v =>  {
            if (v.longitude && v.latitude && v.longitude != "0.0" && v.latitude != "0.0") {
              return (
                <Marker
                  position={[parseFloat(v.latitude), parseFloat(v.longitude)]}
                  key={v.id}>
                  <Popup>{markerContent(v)}</Popup>
                </Marker>
              )
            }
          }
        )}
      </MarkerClusterGroup>
    </StyledMapContainer>
  )
}

