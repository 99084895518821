import React from 'react'
import styled from 'styled-components'
import { GoPrimitiveDot } from 'react-icons/go'
import moment from 'moment'

export const appConstants = {
  MONITORING_EVENT_URL: '/tpm/monitoring_requests/',
  MONITORING_EVENT_JSON_URL: '/tpm/monitoring_requests.json',
  CALENDAR_URL: '/tpm/events_calendar/',
  REVIEW_BOARD_URL: '/tpm/visits',
  USERS_URL: '/users.json',
  MONITORS_URL: '/users.json?job=monitor',
  REVIEWERS_URL: '/users.json?job=reviewer',
  EVENT_STATUSES_URL: '/tpm/request_statuses',
  ARCHIVE_STATUSES_URL: '/tpm/request_statuses?archive=true',
  IFM_STATUSES_URL: '/tpm/request_statuses?ifm=true',
  EVENT_MODES_URL: '/tpm/request_modes',
  ACTIVITIES_URL: '/tpm/activities.json',
  IPS_BY_ACTIVITY_URL: '/tpm/ips_by_activity/',
  IPS_URL: '/tpm/implementing_partners.json',
  GOVERNORATES_URL: '/tpm/governorates.json',
  DISTRICTS_BY_GOVERNORATE_URL: '/tpm/districts_by_governorate/',
  OFFICES_URL: '/tpm/offices.json',
  JUSTIFICATIONS_URL: '/tpm/request_justifications',
  TIME_INTERVALS_URL: '/tpm/time_intervals',
  COLLECTIONS_URL: '/kmp/collections/',
  RESOURCES_URL: '/kmp/resources/',
  SEARCH_URL: '/kmp/search/',
  COLLECTIONS_JSON_URL: '/kmp/collections.json',
  RESOURCES_JSON_URL: '/kmp/resources.json',
  GROUPED_TAGS_URL: '/kmp/grouped_tags/',
  REQUESTS_FOR_RESOURCES_URL: '/tpm/requests_for_resources/',
  IMPORT_EVENTS_URL: '/tpm/bulk_import',
  EVENT_TAGS_URL: '/kmp/tags.json?monitoring_request_tags=true',
  BULK_UPDATE_STATUS_URL: '/tpm/bulk_update_status',
  NOTIFICATIONS_URL: '/tpm/notifications',
  FULCRUM_URL: 'https://web.fulcrumapp.com/',
  NEW_RESOURCE_URL: '/kmp/resources/new',
  NEW_COLLECTION_URL: '/kmp/collections/new',
  ARCHIVE_URL: '/tpm/archive',
  TPM_QUESTIONS_URL: '/tpm/tpm_questions/',
  TPM_ANSWER_TYPES_URL: '/tpm/answer_types',
  FULCRUM_FORMS_URL: '/tpm/fulcrum_forms',
  QUESTION_IMPORT_URL: '/tpm/questions_import',
  SECTORS_URL: '/kmp/tags.json?sectors=true',
  MODALITIES_URL: '/kmp/tags.json?modalities=true',
}

export const setRequest = (e) => {
  const request = {
    id: parseInt(e.id),
    name: e.name,
    request_status: e.request_status_id,
    request_justification_name: e.request_justification_name,
    request_justification: e.request_justification,
    start_date: e.start_date,
    end_date: e.end_date,
    event_start_date: e.event_start_date,
    event_expired: e.event_expired,
    date_requested: e.date_requested,
    date_submitted: e.date_submitted,
    timing_details: e.timing_details,
    user_id: e.user_id,
    reviewer_id: e.reviewer_id,
    activity_id: e.activity_id,
    activity_name: e.activity_name,
    implementing_partner_id: e.implementing_partner_id,
    implementing_partner_name: e.implementing_partner_name,
    governorate_id: e.governorate_id,
    governorate_name: e.governorate_name,
    district_id: e.district_id,
    district_name: e.district_name,
    location: e.location,
    virtual: e.virtual,
    hold: e.hold,
    latitude: e.latitude,
    longitude: e.longitude,
    start_week: e.start_week,
    tag_id: e.tag_id,
    tag2_id: e.tag2_id,
    compliance: e.compliance,
    spotcheck: e.spotcheck,
    has_pickups: e.has_pickups,
    office_id: e.office_id,
    data: {
      poc: e.poc,
      description: e.description,
      beneficiaries: e.beneficiaries,
      report_name: e.report_name,
      request_justification_details: e.request_justification_details,
    }
  }
  return request
}

export const setResource = (e) => {
  const resource = {
    id: parseInt(e.id),
    name: e.name,
    url: e.url,
    description: e.description,
  }
  return resource
}

const GreenTimeDot = styled(GoPrimitiveDot)`
  color: green;
  `;

const YellowTimeDot = styled(GoPrimitiveDot)`
  color: gold;
  `;

const OrangeTimeDot = styled(GoPrimitiveDot)`
  color: orange;
  `;

const RedTimeDot = styled(GoPrimitiveDot)`
  color: red;
  `;

export const renderTimeDots = (startDate, status) => {
    
  let dayDiff = 0
  const now = moment()
  if (startDate && status != 'submitted') {
    const start = moment(startDate)
    dayDiff = now.diff(start, 'days') 
  }

  let dots = [];
  if (dayDiff < 4) {
    _.times(dayDiff, (i) => {
      dots.push(<GreenTimeDot key={i}></GreenTimeDot>)
    })
  } else if (dayDiff > 3 && dayDiff < 6) {
    _.times(dayDiff, (i) => {
      dots.push(<YellowTimeDot key={i}></YellowTimeDot>)
    })
  } else if (dayDiff == 6) {
    _.times(dayDiff, (i) => {
      dots.push(<OrangeTimeDot key={i}></OrangeTimeDot>)
    })
  } else if (dayDiff > 6) {
    _.times(7, (i) => {
      dots.push(<RedTimeDot key={i}></RedTimeDot>)
    })
  }
  
  return (
    <>
    {dots}
    </>
  )
}