import React from 'react'
import styled from 'styled-components'
import Task from './Task'
import { Droppable } from 'react-beautiful-dnd';
import Col from 'react-bootstrap/Col'

const Title = styled.h5`
  padding: 4px;
`;
const TaskList = styled.div`
  padding: 4px;
  border-radius: 5px;
  transition: background-color 0.5s ease;
  background-color: ${props => (props.isDraggingOver ? 'lightgrey' : '')}  !important;
  flex-grow: 1;
  min-height: 540px;
`;

export default function Column(props) {

  return (
    <Col className="border rounded bg-light mx-md-1">

      <Title>{props.column.title}</Title>
        <Droppable 
          droppableId={props.column.id}
          isDropDisabled={props.isDropDisabled}
        >
          {(provided, snapshot) => (
            <TaskList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
            {props.tasks.map((task, index) => <Task key={task.id} task={task} index={index} taskContent={props.taskContent} />)}
            {provided.placeholder}
            </TaskList>
          )}
        </Droppable>

            
    </Col>
  )
}
