import React, { Component }  from 'react'
import moment from 'moment'
import axios from 'axios'
import styled from 'styled-components'
import Board from './boards/Board'
import SortableTable from './tables/SortableTable.js'
import EventMap from './maps/EventMap.js'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import MonitoringRequestForm from './MonitoringRequestForm.js'
import MonitoringRequestInfo from './MonitoringRequestInfo.js'
import { appConstants, setRequest, renderTimeDots } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { CSVLink } from 'react-csv'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'
import { BiLinkExternal, BiDownload } from 'react-icons/bi'
import Fuse from 'fuse.js'


const NewRequestButton = styled(Button)`
  right: 1.5em;
  position: absolute;
  z-index: 0;
  `;

const RemoveExpiredButton = styled(Button)`
  right: 8em;
  position: absolute;
  z-index: 0;
  `;

const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const ExportWrapper = styled(CSVLink)`
  left: 88%;
  top: -6px;
  position: relative;
  z-index: 0;
  `;

const searchOptions = {
  // https://fusejs.io/api/options.html for full list of options
  minMatchCharLength: 3,
  threshold: 0.1,
  distance: 500,
  keys: [
    "name",
    "id",
    "description",
    "beneficiaries",
    "report_name",
    "request_justification_details",
    "poc",
    "location"
  ]
};

moment.updateLocale('en', {
  week: {
      dow: 5,
      doy: 1,
  },
});

export default class TPMDashboard extends Component {

  state = {
    initialData: this.props.initialData.data || [],
    filteredData: this.props.initialData.data || [],
    filters: this.props.initialData.filters,
    tableColumns: this.props.initialData.columns,
    topLevelColumns: this.props.initialData.top_level_columns,
    reviewBoard: this.props.initialData.review_board,
    selectionBoard: this.props.initialData.selection_board,
    viewModal: false,
    selectedRequest: {},
    selectedTab: "board",
    showFilters: true,
    searchTerms: "",
  }

  componentDidMount() {
    const reviewBoard = this.setBoardData(this.state.reviewBoard, this.state.initialData)
    const newState = {
      ...this.state,
      reviewBoard: reviewBoard,
    }
    this.setState(newState)
  }

  refreshData = () => {
    axios.get(this.props.refreshPath)
    .then(res => {
      const monitoringRequests = res.data;
      this.setState({
        initialData: monitoringRequests.data,
      }, () => {
        this.filterData(this.state.filters)
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  // filtering functions
  handleFilterChange = (e, filterBy) => {
    const filters = this.state.filters
    let filterValue = null
    if (e) { filterValue = e.value } 
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    this.filterData(newFilters)
  }

  handleSearch = (e) => {
    this.setState({
      searchTerms: e.target.value
    }, () => {
        this.filterData(this.state.filters)
    })
  }

  filterData = (filters) => {
    const data = this.state.initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey] !== null) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
      }
    }
    if (this.state.searchTerms.length > 2) {
      const fuse = new Fuse(filteredData, searchOptions);
      const searchResults = fuse.search(this.state.searchTerms);
      filteredData = searchResults.map(task => task.item)
    }
    
    const reviewBoard = this.setBoardData(this.state.reviewBoard, filteredData)
    const newState = {
      ...this.state,
      filteredData: filteredData,
      reviewBoard: reviewBoard,
      filters: filters,
    }
    this.setState(newState)
  }

  //  tpm board functions
  setBoardData = (board, data) => {
    const columnKeys = Object.keys(board.columns)
    let newColumns = board.columns
    let newTasks = {}
    for (let key of columnKeys) {
      newColumns[key]["taskIds"] = []
    }
    data.map(task => {
      if (newColumns[task.request_status_id]) {
        task.id = task.id.toString()
        newTasks[task.id] = task
        newColumns[task.request_status_id]["taskIds"].push(task.id)
      }
    })
 
    const newBoard = {
      ...board,
      tasks: newTasks,
      columns: newColumns,
    }
    return newBoard
  }

  dropDisabled = (index, columnId, homeIndex, multidirectional) => {
    if (multidirectional && this.props.userRole !== 'read_only') {
      return false
    } else if (this.props.userRole === 'read_only') {
      return true
    } else {
      return index < homeIndex
    }
  }

  removeExpiredIDs = () => {
    const createdIDs = this.state.reviewBoard.columns.created.taskIds
    const expiredIDs = createdIDs.filter(id => this.state.reviewBoard.tasks[parseInt(id)].event_expired).map(id => parseInt(id))
    const status = "discarded"
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.put(`${appConstants.BULK_UPDATE_STATUS_URL}`, {"request_status": status, "update_status_ids": expiredIDs} )
    .then(res => {
      this.refreshData()
    })
    .catch(error => {
      console.log(error)
    })
  }

  taskContent = (task) => {
    return (
      <div>
        <strong><a href='#' onClick={() => {this.editRequest(task)}}>{task.id}. {task.name}</a></strong> <br/>
        {I18n.t('activerecord.attributes.monitoring_request.date_requested')}: {task.date_requested} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.event_start_date')}: {task.event_start_date} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.start_date')}: {task.start_date} <br/>
        {this.renderSubmitDate(task)} 
        {I18n.t('activerecord.attributes.monitoring_request.activity')}: {task.activity_abbreviation} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.governorate')}: {task.governorate_name} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.district')}: {task.district_name} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.user')}: {task.monitor_last_name}  <br/>
        {this.renderECMLink(task)}
        {this.renderLinkedRecords(task.linked_records)}
        {this.renderReportResources(task)}
        {renderTimeDots(task.start_date, task.request_status_id)}
      </div>
    )
  }

  renderSubmitDate = (task) => {
    return task.request_status_id == 'submitted' ? (
    <>
      {I18n.t('activerecord.attributes.monitoring_request.date_submitted')}: {task.date_submitted} <br/>
      </>
    ) : ""
  }

  renderECMLink = (task) => {
    return task.compliance ? (
      <>
        <a href={task.compliance} target="_blank">{I18n.t('activerecord.attributes.monitoring_request.compliance_doc')}</a> <br />
      </>
      
     ) : ""
  }

  renderReportResources = (task) => {
    return (task.resource_ids && (task.request_status_id == 'third_review' || task.request_status_id == 'submitted')) ? (
      <>
        {task.resource_ids.map((id) => 
          <a href={appConstants.RESOURCES_URL + id} target="_blank" key={id}><BiDownload /></a>
        )}
     </>
     ) : ""
  }

  renderLinkedRecords = (records) => {
    return (this.props.userRole !== 'read_only') ? (
      <>
        {records.map((record) => 
          <a href={appConstants.FULCRUM_URL + "records/" + record} target="_blank" rel="noopener noreferrer" key={record}><BiLinkExternal /></a>
        )}
     </>
     ) : ""
  }

  requestModalLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <a href='#' onClick={() => {this.editRequest(row.original)}}>{row["original"]["name"]}</a>
        : cell.render('Cell') 
    )
  }

  persistStatus(id, status) {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.put(`${appConstants.MONITORING_EVENT_URL}${id}`, {"request_status": status, "date_submitted": moment()} )
    .then(res => {
      this.refreshData()
    })
    .catch(error => {
      console.log(error)
    })
  }

  onReviewDragStart = start => {
    this.onDragStart(start,"reviewBoard")
  }

  onReviewDragEnd = result => {
    this.onDragEnd(result,"reviewBoard")
  }

  onDragStart = (start, board) => {
    const homeIndex = this.state[board].columnOrder.indexOf(start.source.droppableId);
  
    const draggingState = {
      ...this.state[board],
      homeIndex: homeIndex
    }
    this.setState({[board]: draggingState})
  }

  onDragEnd = (result, board) => {
    const droppedState = {
      ...this.state[board],
      homeIndex: null
    }
    this.setState({[board]: droppedState})

    const { destination, source, draggableId } = result;

    // Moving aborted
    if(!destination) {
      return;
    }

    //Moving to original spot
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = this.state[board].columns[source.droppableId]
    const finish = this.state[board].columns[destination.droppableId]

    // Moving within same column 
    if(start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };
    
      const newState = {
        ...this.state[board],
        columns: {
          ...this.state[board].columns,
          [newColumn.id]: newColumn,
        },
      };
    
      this.setState({[board]: newState})
      return
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...this.state[board],
      columns: {
        ...this.state[board].columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    this.setState({[board]: newState})
    this.persistStatus(draggableId, destination.droppableId)
  }

  // tpm calendar functions
  getEventColor = (e) => {
    const style = {
      backgroundColor: e.hold ? '#c13851' : ''
    }
    return {
      style: style
    }
  }

  // modal/form functions
  editRequest = (task) => {
    const request = setRequest(task)
    this.setState({
      selectedRequest: request,
      viewModal: true
    })
  }

  newRequest = () => {
    this.setState({
    viewModal: true,
    selectedRequest: {}
    })
  }

  closeModal = () => {
    this.setState({
      selectedRequest: {},
      viewModal: false
    })
  }

  reportingData() {
    return this.state.filteredData.map(mr => (
      {
        "name": mr.name,
        "date_requested": mr.date_requested,
        "start_date": mr.start_date,
        "date_submitted": mr.date_submitted,
        "activity": mr.activity_abbreviation,
        "ip": mr.implementing_partner_name,
        "governorate": mr.governorate_name,
        "district": mr.district_name,
        "status": mr.top_level_status,
      })
    )
  }

  renderMonitoringRequestModalContent() {
    return this.props.userRole === 'read_only' ? (
      <MonitoringRequestInfo col={6}
        monitoringRequest={this.state.selectedRequest} />
    ) : (
      <MonitoringRequestForm col={6}
        monitoring_request={this.state.selectedRequest}
        authenticityToken={this.props.authenticityToken}
        userRole={this.props.userRole}
        refreshData={this.refreshData}
        closeModal={this.closeModal} />
    )
  }

  renderRequestModal() {
    return (
      <Modal
        show={this.state.viewModal}
        onHide={this.closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
        <Modal.Title>{`${I18n.t('activerecord.attributes.monitoring_request.monitoring_event')} ${this.state.selectedRequest.id ? this.state.selectedRequest.id : ""}` }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderMonitoringRequestModalContent()}
        </Modal.Body>
      </Modal>
    )
  }

  renderNewRequestButton() {
    return (this.props.userRole !== 'read_only') ?
    (
      <NewRequestButton variant="outline-success" size="sm" onClick={() => this.newRequest()}>{I18n.t('monitoring_request.new')}</NewRequestButton>
    ) : ""
  }

  renderRemoveExpiredButton() {
    return (this.props.userRole !== 'read_only' && this.props.bulkRemove) ?
    (
      <RemoveExpiredButton variant="outline-danger" size="sm" onClick={() => this.removeExpiredIDs()}>{I18n.t('monitoring_request.remove_expired')}</RemoveExpiredButton>
    ) : ""
  }

  renderFiltersButton() {
    return (
      <FiltersButton 
        onClick={() => this.setState({showFilters: !this.state.showFilters})} 
        aria-controls="collapse-filters" 
        aria-expanded={this.state.showFilters}>
          <h4>{this.state.showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }

  renderReviewBoard() {
    return (this.state.selectedTab == "board") ?
      (
        <Board 
          onDragStart={this.onReviewDragStart}
          onDragEnd={this.onReviewDragEnd}
          dropDisabled={this.dropDisabled}
          taskContent={this.taskContent}
          initialData={this.state.reviewBoard}
        />
      ) : ""
  }

  renderList() {
    return (this.state.selectedTab == "list") ?
      (
        <SortableTable
          data={this.state.filteredData}
          columns={this.state.tableColumns}
          useRenderFunction={true}
          cellContent={this.requestModalLink}
        />  
      ) : ""
  }
  
  renderReportingList() {
    return (this.state.selectedTab == "reporting_list") ?
      (
        <SortableTable
          data={this.state.filteredData}
          columns={this.state.topLevelColumns}
          useRenderFunction={true}
          cellContent={this.requestModalLink}
        />  
      ) : ""
  }
  renderMap() {
    return (this.state.selectedTab == "map") ?
      (
        <EventMap
          data={this.state.filteredData}
          markerContent={this.taskContent}
        />  
      ) : ""
  }

  renderCalendar() {
    return (this.state.selectedTab == "calendar") ?
      (
        <Calendar
          localizer={momentLocalizer(moment)}
          events={this.state.filteredData}
          startAccessor="unformatted_start_date"
          endAccessor="unformatted_end_date"
          drillDownView="agenda"
          onSelectEvent={this.editRequest}
          style={{ height: 500 }}
          popup={true}
          eventPropGetter={this.getEventColor}
          step={360}
          timeslots={24}
          defaultView={'week'}
        />
      ) : ""
  }

  render() {
    return (
      <>
        {this.renderRequestModal()}
        <div>
          <Collapse in={this.state.showFilters}>
            <div id="collapse-filters">
              <MonitoringRequestFilters handleFilterChange={this.handleFilterChange} handleSearch={this.handleSearch} includedFilters={this.state.filters} />
            </div>
          </Collapse>
          {this.renderFiltersButton()}
        </div>
        
        <div>
          {this.renderNewRequestButton()}
          {this.renderRemoveExpiredButton()}
          <Tabs defaultActiveKey="board" id="listTabs" onSelect={(selectedKey) => this.setState({selectedTab: selectedKey})}>
            <Tab eventKey="board" title={this.props.header}>
              <br/>
              {this.renderReviewBoard()}
            </Tab>
            <Tab eventKey="list" title={I18n.t("headers.monitoring_request.list")}>
              <br/>
              <ExportWrapper  data={this.state.filteredData}>{I18n.t("headers.monitoring_request.export")}</ExportWrapper>
              {this.renderList()}
            </Tab>
            <Tab eventKey="reporting_list" title={I18n.t("headers.monitoring_request.reporting_list")}>
              <br/>
              <ExportWrapper  data={this.reportingData()}>{I18n.t("headers.monitoring_request.export")}</ExportWrapper>
              {this.renderReportingList()}
            </Tab>
            <Tab eventKey="calendar" title={I18n.t("headers.monitoring_request.calendar")}>
              <br/>
              {this.renderCalendar()}
            </Tab>
            <Tab eventKey="map" title={I18n.t("headers.monitoring_request.map")}>
              <br/>
              {this.renderMap()}
            </Tab>
          </Tabs>
        </div>
      </>
    )
  }
}

