import React from 'react'
import I18n from 'i18n-js/locales.js'
import { Container, Row, Col } from 'react-bootstrap'

export default function MonitoringRequestInfo(props) {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.name')}: </strong>
          {props.monitoringRequest.name}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.activity')}: </strong>
          {props.monitoringRequest.activity_name}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.implementing_partner')}: </strong>
          {props.monitoringRequest.implementing_partner_name}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.governorate')}: </strong>
          {props.monitoringRequest.governorate_name}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.district')}: </strong>
          {props.monitoringRequest.district_name}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.location')}: </strong>
          {props.monitoringRequest.location}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.event_start_date')}: </strong>
          {props.monitoringRequest.event_start_date}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.start_date')}: </strong>
          {props.monitoringRequest.start_date}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.timing_details')}: </strong>
          {props.monitoringRequest.timing_details}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.reviewer')}: </strong>
          {props.monitoringRequest.reviewer}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.user')}: </strong>
          {props.monitoringRequest.user}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.latitude')}: </strong>
          {props.monitoringRequest.latitude}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.longitude')}: </strong>
          {props.monitoringRequest.longitude}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.description')}: </strong>
          {props.monitoringRequest.data ? props.monitoringRequest.data.description : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.poc')}: </strong>
          {props.monitoringRequest.data ? props.monitoringRequest.data.poc : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.beneficiaries')}: </strong>
          {props.monitoringRequest.data ? props.monitoringRequest.data.beneficiaries : ""}</p>
        </Col>
        <Col md={3}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.security_hold')}: </strong>
          {props.monitoringRequest.hold  ? 'Yes' : 'No'}</p>
        </Col>
        <Col md={3}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.virtual')}: </strong>
          {props.monitoringRequest.virtual  ? 'Yes' : 'No'}</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.report_name')}: </strong>
          {props.monitoringRequest.data ? props.monitoringRequest.data.report_name : ""}</p>
        </Col>
      </Row>
    </Container>
  )
}
